<template>
  <VApp theme="dark">
    <VLayout class="rounded rounded-md">
      <VAppBar>
        <VTabs
          center-active
          show-arrows
        >
          <VTab
            :stacked="smAndDown"
            v-for="(item, index) in sourceData.VBottomNavigation.buttons"
            :key="index"
            :to="item.to"
            :prepend-icon="item.icon"
            :text="item.text"
          />
        </VTabs>
      </VAppBar>
      <VMain>
        <router-view />
      </VMain>
    </VLayout>
  </VApp>
</template>

<script setup>
  import sourceData from '@/data/App.json'
  import { useDisplay } from 'vuetify'

  const { smAndDown } = useDisplay()

</script>

<style>
  body {
    background-color: #fdf5e6;
    margin: 0px;
    font-family: 'Noto Sans TC';
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-color: #fdf5e6;
  }

  nav {
    padding: 30px;
  }

  nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  nav a.router-link-exact-active {
    color: #42b983;
  }

  /* set the v-table odd/even row with different color */
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
  }

</style>
