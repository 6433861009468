import { createRouter, createWebHistory } from 'vue-router'
import liff from '@line/liff'

const routes = [
  {
    name: 'home',
    path: '/',
    component: () => import('@/views/HomeView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    name: 'shop',
    path: '/shop',
    component: () => import('@/views/ShopView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    name: 'notfound',
    path: '/404',
    component: ()=> import('@/views/NotFoundView.vue')
  },
  {
    name: 'reservation',
    path: '/reservation',
    component: ()=> import('@/views/ReservationView.vue')
  },
  {
    name: 'uid',
    path: '/uid/:unitid',
    component: () => import('@/views/UnitStreamView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  if(to.meta.requiresAuth){
    if(liff.isLoggedIn()){
      next()
    }else{
      next(false)
    }
  }else{

    next()

  }
})

export default router
